<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        اضافة جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/users/roles')"
        />
      </template>
    </Toolbar>
    <div class="form-card">
      <div class="mb-3">
        <label for="name" class="form-label required">
          الاسم
          <span>*</span>
        </label>
        <input
          class="form-control"
          id="name"
          v-model="body.name"
          :class="{ 'is-invalid': isInvalid && !body.name }"
          required
        />
      </div>

      <div class="roles text-right">
        <div class="mb-3 ">
          <h6>مدير النظام</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="allRoles"
              v-model="allRoles"
              @input="onToggle"
              :value="true"
            />
            <label for="allRoles">كل الصلاحيات</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>القائمه الرئيسيه</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="systemMenuVeiw"
              name="roles"
              value="systemMenuVeiw"
              v-model="body.roles"
            />
            <label for="systemMenuVeiw">النظام</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="usersMenuVeiw"
              name="roles"
              value="usersMenuVeiw"
              v-model="body.roles"
            />
            <label for="usersMenuVeiw">المستخدمين</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="salesMenuVeiw"
              name="roles"
              value="salesMenuVeiw"
              v-model="body.roles"
            />
            <label for="salesMenuVeiw">المبيعات</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="technicalSupportMenuVeiw"
              name="roles"
              value="technicalSupportMenuVeiw"
              v-model="body.roles"
            />
            <label for="technicalSupportMenuVeiw">التركيبات</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="secretaryMenuVeiw"
              name="roles"
              value="secretaryMenuVeiw"
              v-model="body.roles"
            />
            <label for="secretaryMenuVeiw">السكرتاريه</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryMenuVeiw"
              name="roles"
              value="inventoryMenuVeiw"
              v-model="body.roles"
            />
            <label for="inventoryMenuVeiw">المخازن</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsMenuVeiw"
              name="roles"
              value="reportsMenuVeiw"
              v-model="body.roles"
            />
            <label for="reportsMenuVeiw">التقرير</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>الشاشه الرئيسيه</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="homeNumberingVeiw"
              name="roles"
              value="homeNumberingVeiw"
              v-model="body.roles"
            />
            <label for="homeNumberingVeiw">عرض ملخص الارقام</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="homedoutVeiw"
              name="roles"
              value="homedoutVeiw"
              v-model="body.roles"
            />
            <label for="homedoutVeiw">عرض ملخص دائرى</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="homeLineVeiw"
              name="roles"
              value="homeLineVeiw"
              v-model="body.roles"
            />
            <label for="homeLineVeiw">عرض ملخص عملاء وعقود</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="homePriceVeiw"
              name="roles"
              value="homePriceVeiw"
              v-model="body.roles"
            />
            <label for="homePriceVeiw">عرض ملخص اسعار العقود</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>اعدادت النظام</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="companyVeiw"
              name="roles"
              value="companyVeiw"
              v-model="body.roles"
            />
            <label for="companyVeiw">عرض</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>اعدادت الاشعارات</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="notificationsVeiw"
              name="roles"
              value="notificationsVeiw"
              v-model="body.roles"
            />
            <label for="notificationsVeiw">عرض</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>فروع الشركه</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="branchesVeiw"
              name="roles"
              value="branchesVeiw"
              v-model="body.roles"
            />
            <label for="branchesVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="branchesAdd"
              name="roles"
              value="branchesAdd"
              v-model="body.roles"
            />
            <label for="branchesAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="branchesEdit"
              name="roles"
              value="branchesEdit"
              v-model="body.roles"
            />
            <label for="branchesEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="branchesDelete"
              name="roles"
              value="branchesDelete"
              v-model="body.roles"
            />
            <label for="branchesDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>الدول</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="countriesVeiw"
              name="roles"
              value="countriesVeiw"
              v-model="body.roles"
            />
            <label for="countriesVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="countriesAdd"
              name="roles"
              value="countriesAdd"
              v-model="body.roles"
            />
            <label for="countriesAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="countriesEdit"
              name="roles"
              value="countriesEdit"
              v-model="body.roles"
            />
            <label for="countriesEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="countriesDelete"
              name="roles"
              value="countriesDelete"
              v-model="body.roles"
            />
            <label for="countriesDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>المدن</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="citiesVeiw"
              name="roles"
              value="citiesVeiw"
              v-model="body.roles"
            />
            <label for="citiesVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="citiesAdd"
              name="roles"
              value="citiesAdd"
              v-model="body.roles"
            />
            <label for="citiesAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="citiesEdit"
              name="roles"
              value="citiesEdit"
              v-model="body.roles"
            />
            <label for="citiesEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="citiesDelete"
              name="roles"
              value="citiesDelete"
              v-model="body.roles"
            />
            <label for="citiesDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>العملات</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="currenciesVeiw"
              name="roles"
              value="currenciesVeiw"
              v-model="body.roles"
            />
            <label for="currenciesVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="currenciesAdd"
              name="roles"
              value="currenciesAdd"
              v-model="body.roles"
            />
            <label for="currenciesAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="currenciesEdit"
              name="roles"
              value="currenciesEdit"
              v-model="body.roles"
            />
            <label for="currenciesEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="currenciesDelete"
              name="roles"
              value="currenciesDelete"
              v-model="body.roles"
            />
            <label for="currenciesDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>المنتجات والخدمات</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="productsVeiw"
              name="roles"
              value="productsVeiw"
              v-model="body.roles"
            />
            <label for="productsVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="productsAdd"
              name="roles"
              value="productsAdd"
              v-model="body.roles"
            />
            <label for="productsAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="productsEdit"
              name="roles"
              value="productsEdit"
              v-model="body.roles"
            />
            <label for="productsEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="productsDelete"
              name="roles"
              value="productsDelete"
              v-model="body.roles"
            />
            <label for="productsDelete">حذف</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="itemsAdd"
              name="roles"
              value="itemsAdd"
              v-model="body.roles"
            />
            <label for="itemsAdd">اضافة الخدمه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="itemsEdit"
              name="roles"
              value="itemsEdit"
              v-model="body.roles"
            />
            <label for="itemsEdit">تعديل الخدمه</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="itemsDelete"
              name="roles"
              value="itemsDelete"
              v-model="body.roles"
            />
            <label for="itemsDelete">حذف الخدمه</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>بنود التقيم</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="surveysVeiw"
              name="roles"
              value="surveysVeiw"
              v-model="body.roles"
            />
            <label for="surveysVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="surveysAdd"
              name="roles"
              value="surveysAdd"
              v-model="body.roles"
            />
            <label for="surveysAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="surveysEdit"
              name="roles"
              value="surveysEdit"
              v-model="body.roles"
            />
            <label for="surveysEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="surveysDelete"
              name="roles"
              value="surveysDelete"
              v-model="body.roles"
            />
            <label for="surveysDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>الادارات</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="administrationVeiw"
              name="roles"
              value="administrationVeiw"
              v-model="body.roles"
            />
            <label for="administrationVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="administrationAdd"
              name="roles"
              value="administrationAdd"
              v-model="body.roles"
            />
            <label for="administrationAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="administrationsEdit"
              name="roles"
              value="administrationsEdit"
              v-model="body.roles"
            />
            <label for="administrationsEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="administrationsDelete"
              name="roles"
              value="administrationsDelete"
              v-model="body.roles"
            />
            <label for="administrationsDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>الاقسام</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="sectionsVeiw"
              name="roles"
              value="sectionsVeiw"
              v-model="body.roles"
            />
            <label for="sectionsVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="sectionsAdd"
              name="roles"
              value="sectionsAdd"
              v-model="body.roles"
            />
            <label for="sectionsAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="sectionsEdit"
              name="roles"
              value="sectionsEdit"
              v-model="body.roles"
            />
            <label for="sectionsEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="sectionsDelete"
              name="roles"
              value="sectionsDelete"
              v-model="body.roles"
            />
            <label for="sectionsDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>الوظائف</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="employeesVeiw"
              name="roles"
              value="employeesVeiw"
              v-model="body.roles"
            />
            <label for="employeesVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="employeesAdd"
              name="roles"
              value="employeesAdd"
              v-model="body.roles"
            />
            <label for="employeesAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="employeesEdit"
              name="roles"
              value="employeesEdit"
              v-model="body.roles"
            />
            <label for="employeesEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="employeesDelete"
              name="roles"
              value="employeesDelete"
              v-model="body.roles"
            />
            <label for="employeesDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>مجموعه العملاء</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="clientGroupsVeiw"
              name="roles"
              value="clientGroupsVeiw"
              v-model="body.roles"
            />
            <label for="clientGroupsVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="clientGroupsAdd"
              name="roles"
              value="clientGroupsAdd"
              v-model="body.roles"
            />
            <label for="clientGroupsAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="clientGroupsEdit"
              name="roles"
              value="clientGroupsEdit"
              v-model="body.roles"
            />
            <label for="clientGroupsEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="clientGroupsDelete"
              name="roles"
              value="clientGroupsDelete"
              v-model="body.roles"
            />
            <label for="clientGroupsDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>السجل</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="logsVeiw"
              name="roles"
              value="logsVeiw"
              v-model="body.roles"
            />
            <label for="logsVeiw">عرض</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>الضرائب</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="taxesVeiw"
              name="roles"
              value="taxesVeiw"
              v-model="body.roles"
            />
            <label for="taxesVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="taxesAdd"
              name="roles"
              value="taxesAdd"
              v-model="body.roles"
            />
            <label for="taxesAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="taxesEdit"
              name="roles"
              value="taxesEdit"
              v-model="body.roles"
            />
            <label for="taxesEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="taxesDelete"
              name="roles"
              value="taxesDelete"
              v-model="body.roles"
            />
            <label for="taxesDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>المستودعات</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="warehouseVeiw"
              name="roles"
              value="warehouseVeiw"
              v-model="body.roles"
            />
            <label for="warehouseVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="warehouseAdd"
              name="roles"
              value="warehouseAdd"
              v-model="body.roles"
            />
            <label for="warehouseAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="warehouseEdit"
              name="roles"
              value="warehouseEdit"
              v-model="body.roles"
            />
            <label for="warehouseEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="warehouseDelete"
              name="roles"
              value="warehouseDelete"
              v-model="body.roles"
            />
            <label for="warehouseDelete">حذف</label>
          </div>
        </div>
        <div class="mb-3">
          <h6>الصلاحيات</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="rolesVeiw"
              name="roles"
              value="rolesVeiw"
              v-model="body.roles"
            />
            <label for="rolesVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="rolesAdd"
              name="roles"
              value="rolesAdd"
              v-model="body.roles"
            />
            <label for="rolesAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="rolesEdit"
              name="roles"
              value="rolesEdit"
              v-model="body.roles"
            />
            <label for="rolesEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="rolesDelete"
              name="roles"
              value="rolesDelete"
              v-model="body.roles"
            />
            <label for="rolesDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>مستخدمين النظام</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="adminsVeiw"
              name="roles"
              value="adminsVeiw"
              v-model="body.roles"
            />
            <label for="adminsVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="adminsAdd"
              name="roles"
              value="adminsAdd"
              v-model="body.roles"
            />
            <label for="adminsAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="adminsEdit"
              name="roles"
              value="adminsEdit"
              v-model="body.roles"
            />
            <label for="adminsEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="adminsDelete"
              name="roles"
              value="adminsDelete"
              v-model="body.roles"
            />
            <label for="adminsDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>العملاء</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="clientsVeiw"
              name="roles"
              value="clientsVeiw"
              v-model="body.roles"
            />
            <label for="clientsVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="clientsAdd"
              name="roles"
              value="clientsAdd"
              v-model="body.roles"
            />
            <label for="clientsAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="clientsEdit"
              name="roles"
              value="clientsEdit"
              v-model="body.roles"
            />
            <label for="clientsEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="clientsDelete"
              name="roles"
              value="clientsDelete"
              v-model="body.roles"
            />
            <label for="clientsDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>العقود</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="contractsVeiw"
              name="roles"
              value="contractsVeiw"
              v-model="body.roles"
            />
            <label for="contractsVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="contractsAdd"
              name="roles"
              value="contractsAdd"
              v-model="body.roles"
            />
            <label for="contractsAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="contractsEdit"
              name="roles"
              value="contractsEdit"
              v-model="body.roles"
            />
            <label for="contractsEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="contractsDelete"
              name="roles"
              value="contractsDelete"
              v-model="body.roles"
            />
            <label for="contractsDelete">حذف</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="contractsShowAllData"
              name="roles"
              value="contractsShowAllData"
              v-model="body.roles"
            />
            <label for="contractsShowAllData">مشاهده كل العقود</label>
          </div>

          <!-- contractsWithoutPayment -->
          <div class="p-field-checkbox">
            <Checkbox
              id="contractsWithoutPayment"
              name="roles"
              value="contractsWithoutPayment"
              v-model="body.roles"
            />
            <label for="contractsWithoutPayment">
              تفعيل العقد مباشر بدون السداد
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="contractsDate"
              name="roles"
              value="contractsDate"
              v-model="body.roles"
            />
            <label for="contractsDate">
              ادخل المعاملات بتاريخ قديم
            </label>
          </div>
        </div>

        <div class="mb-3">
          <h6>شاشه الحسابات</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="collectionVeiw"
              name="roles"
              value="collectionVeiw"
              v-model="body.roles"
            />
            <label for="collectionVeiw">عرض</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="paymentTypeAdd"
              name="roles"
              value="paymentTypeAdd"
              v-model="body.roles"
            />
            <label for="paymentTypeAdd">استلام الدفعات</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="paymentTypeEdit"
              name="roles"
              value="paymentTypeEdit"
              v-model="body.roles"
            />
            <label for="paymentTypeEdit">تعديل استلام الدفعات</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>المخازن</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="materialsVeiw"
              name="roles"
              value="materialsVeiw"
              v-model="body.roles"
            />
            <label for="materialsVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="materialsAdd"
              name="roles"
              value="materialsAdd"
              v-model="body.roles"
            />
            <label for="materialsAdd">اضافه</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="materialsDelete"
              name="roles"
              value="materialsDelete"
              v-model="body.roles"
            />
            <label for="materialsDelete">حذف</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="orderDone"
              name="roles"
              value="orderDone"
              v-model="body.roles"
            />
            <label for="orderDone">الطلب</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="editDone"
              name="roles"
              value="editDone"
              v-model="body.roles"
            />
            <label for="editDone">الحجز</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="chargedDone"
              name="roles"
              value="chargedDone"
              v-model="body.roles"
            />
            <label for="chargedDone">الشحن</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="orderDoneEdit"
              name="roles"
              value="orderDoneEdit"
              v-model="body.roles"
            />
            <label for="orderDoneEdit"> تعديل الطلب</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="editDoneEdit"
              name="roles"
              value="editDoneEdit"
              v-model="body.roles"
            />
            <label for="editDoneEdit"> تعديل الحجز</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="chargedDoneEdit"
              name="roles"
              value="chargedDoneEdit"
              v-model="body.roles"
            />
            <label for="chargedDoneEdit"> تعديل الشحن</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="materialsEdit"
              name="roles"
              value="materialsEdit"
              v-model="body.roles"
            />
            <label for="materialsEdit">اضافه ملاحظات</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>متابعة العملاء المستهدفين</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="tasksVeiw"
              name="roles"
              value="tasksVeiw"
              v-model="body.roles"
            />
            <label for="tasksVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="tasksAdd"
              name="roles"
              value="tasksAdd"
              v-model="body.roles"
            />
            <label for="tasksAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="tasksEdit"
              name="roles"
              value="tasksEdit"
              v-model="body.roles"
            />
            <label for="tasksEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="tasksDelete"
              name="roles"
              value="tasksDelete"
              v-model="body.roles"
            />
            <label for="tasksDelete">حذف</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="tasksAll"
              name="roles"
              value="tasksAll"
              v-model="body.roles"
            />
            <label for="tasksAll">مشاهده كل المهام</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>مهام عمل</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="tasksOrderVeiw"
              name="roles"
              value="tasksOrderVeiw"
              v-model="body.roles"
            />
            <label for="tasksOrderVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="tasksOrderAdd"
              name="roles"
              value="tasksOrderAdd"
              v-model="body.roles"
            />
            <label for="tasksOrderAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="tasksOrderEdit"
              name="roles"
              value="tasksOrderEdit"
              v-model="body.roles"
            />
            <label for="tasksOrderEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="tasksOrderDelete"
              name="roles"
              value="tasksOrderDelete"
              v-model="body.roles"
            />
            <label for="tasksOrderDelete">حذف</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="tasksOrderAll"
              name="roles"
              value="tasksOrderAll"
              v-model="body.roles"
            />
            <label for="tasksOrderAll">مشاهده كل المهام</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>تنفيذ مهام عمل</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="tasksOrderDoneVeiw"
              name="roles"
              value="tasksOrderDoneVeiw"
              v-model="body.roles"
            />
            <label for="tasksOrderDoneVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="tasksOrderDoneEdit"
              name="roles"
              value="tasksOrderDoneEdit"
              v-model="body.roles"
            />
            <label for="tasksOrderDoneEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="tasksOrderDoneAll"
              name="roles"
              value="tasksOrderDoneAll"
              v-model="body.roles"
            />
            <label for="tasksOrderDoneAll">مشاهده كل المهام</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>اوامر الشراء</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="purchasesVeiw"
              name="roles"
              value="purchasesVeiw"
              v-model="body.roles"
            />
            <label for="purchasesVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="purchasesAdd"
              name="roles"
              value="purchasesAdd"
              v-model="body.roles"
            />
            <label for="purchasesAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="purchasesEdit"
              name="roles"
              value="purchasesEdit"
              v-model="body.roles"
            />
            <label for="purchasesEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="purchasesDelete"
              name="roles"
              value="purchasesDelete"
              v-model="body.roles"
            />
            <label for="purchasesDelete">حذف</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="purchasesOrderDone"
              name="roles"
              value="purchasesOrderDone"
              v-model="body.roles"
            />
            <label for="purchasesOrderDone">طلب</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="purchasesOrderDelivery"
              name="roles"
              value="purchasesOrderDelivery"
              v-model="body.roles"
            />
            <label for="purchasesOrderDelivery">استلام</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>أوامر التركيب</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="installationsVeiw"
              name="roles"
              value="installationsVeiw"
              v-model="body.roles"
            />
            <label for="installationsVeiw">عرض</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="installationsEdit"
              name="roles"
              value="installationsEdit"
              v-model="body.roles"
            />
            <label for="installationsEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="installtionsToDelivery"
              name="roles"
              value="installtionsToDelivery"
              v-model="body.roles"
            />
            <label for="installtionsToDelivery">التحويل الى التسليم</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="installationsVeiwAll"
              name="roles"
              value="installationsVeiwAll"
              v-model="body.roles"
            />
            <label for="installationsVeiwAll">عرض الكل</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>توزيع اوامر التركيب</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="distributionsVeiw"
              name="roles"
              value="distributionsVeiw"
              v-model="body.roles"
            />
            <label for="distributionsVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="distributionsVeiwAll"
              name="roles"
              value="distributionsVeiwAll"
              v-model="body.roles"
            />
            <label for="distributionsVeiwAll">عرض الكل</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>اوامر التشغيل</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="operationsVeiw"
              name="roles"
              value="operationsVeiw"
              v-model="body.roles"
            />
            <label for="operationsVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="operationsAll"
              name="roles"
              value="operationsAll"
              v-model="body.roles"
            />
            <label for="operationsAll">مشاهده كل اوامر التشغيل</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>مراجعة اوامر التشغيل</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="revisionsVeiw"
              name="roles"
              value="revisionsVeiw"
              v-model="body.roles"
            />
            <label for="revisionsVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="revisionsVeiwAll"
              name="roles"
              value="revisionsVeiwAll"
              v-model="body.roles"
            />
            <label for="revisionsVeiwAll">عرض الكل</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>ملاحظات تسليم العملاء</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="deliveryNotesVeiw"
              name="roles"
              value="deliveryNotesVeiw"
              v-model="body.roles"
            />
            <label for="deliveryNotesVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="deliveryToInstalltion"
              name="roles"
              value="deliveryToInstalltion"
              v-model="body.roles"
            />
            <label for="deliveryToInstalltion">ارجاع الى قسم التشغيل</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="deliveryToDelivery"
              name="roles"
              value="deliveryToDelivery"
              v-model="body.roles"
            />
            <label for="deliveryToDelivery">ارجاع الى التسليم</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>التسليم الى العميل</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="deliveryVeiw"
              name="roles"
              value="deliveryVeiw"
              v-model="body.roles"
            />
            <label for="deliveryVeiw">عرض</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="deliveryToSecretary"
              name="roles"
              value="deliveryToSecretary"
              v-model="body.roles"
            />
            <label for="deliveryToSecretary">التحويل الى اغلاق العقود</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>اغلاق العقود</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="welcomeVeiw"
              name="roles"
              value="welcomeVeiw"
              v-model="body.roles"
            />
            <label for="welcomeVeiw">عرض</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="welcomeEdit"
              name="roles"
              value="welcomeEdit"
              v-model="body.roles"
            />
            <label for="welcomeEdit">التحويل الى تم الاتصال</label>
          </div>
        </div>

        <!-- <div class="mb-3">
          <h6>تقيم العملاء للعقود</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="secretarySurveysVeiw"
              name="roles"
              value="secretarySurveysVeiw"
              v-model="body.roles"
            />
            <label for="secretarySurveysVeiw">اضافة</label>
          </div>
        </div> -->

        <div class="mb-3">
          <h6>التعاميم</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="circularsVeiw"
              name="roles"
              value="circularsVeiw"
              v-model="body.roles"
            />
            <label for="circularsVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="circularsAdd"
              name="roles"
              value="circularsAdd"
              v-model="body.roles"
            />
            <label for="circularsAdd">اضافة</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="circularsDelete"
              name="roles"
              value="circularsDelete"
              v-model="body.roles"
            />
            <label for="circularsDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>مجموعه الاصناف</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="itemsGroupsVeiw"
              name="roles"
              value="itemsGroupsVeiw"
              v-model="body.roles"
            />
            <label for="itemsGroupsVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="itemsGroupsAdd"
              name="roles"
              value="itemsGroupsAdd"
              v-model="body.roles"
            />
            <label for="itemsGroupsAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="itemsGroupsEdit"
              name="roles"
              value="itemsGroupsEdit"
              v-model="body.roles"
            />
            <label for="itemsGroupsEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="itemsGroupsDelete"
              name="roles"
              value="itemsGroupsDelete"
              v-model="body.roles"
            />
            <label for="itemsGroupsDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>انواع الاصناف</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="itemsTypesVeiw"
              name="roles"
              value="itemsTypesVeiw"
              v-model="body.roles"
            />
            <label for="itemsTypesVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="itemsTypesAdd"
              name="roles"
              value="itemsTypesAdd"
              v-model="body.roles"
            />
            <label for="itemsTypesAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="itemsTypesEdit"
              name="roles"
              value="itemsTypesEdit"
              v-model="body.roles"
            />
            <label for="itemsTypesEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="itemsTypesDelete"
              name="roles"
              value="itemsTypesDelete"
              v-model="body.roles"
            />
            <label for="itemsTypesDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>الوحدات</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="itemsUnitsVeiw"
              name="roles"
              value="itemsUnitsVeiw"
              v-model="body.roles"
            />
            <label for="itemsUnitsVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="itemsUnitsAdd"
              name="roles"
              value="itemsUnitsAdd"
              v-model="body.roles"
            />
            <label for="itemsUnitsAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="itemsUnitsEdit"
              name="roles"
              value="itemsUnitsEdit"
              v-model="body.roles"
            />
            <label for="itemsUnitsEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="itemsUnitsDelete"
              name="roles"
              value="itemsUnitsDelete"
              v-model="body.roles"
            />
            <label for="itemsUnitsDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>الاصناف المخزنيه</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="storeItemsVeiw"
              name="roles"
              value="storeItemsVeiw"
              v-model="body.roles"
            />
            <label for="storeItemsVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="storeItemsAdd"
              name="roles"
              value="storeItemsAdd"
              v-model="body.roles"
            />
            <label for="storeItemsAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="storeItemsEdit"
              name="roles"
              value="storeItemsEdit"
              v-model="body.roles"
            />
            <label for="storeItemsEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="storeItemsDelete"
              name="roles"
              value="storeItemsDelete"
              v-model="body.roles"
            />
            <label for="storeItemsDelete">حذف</label>
          </div>
        </div>

        <div class="mb-3">
          <h6>الرصيد الافتتاحي</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="openingBalanceVeiw"
              name="roles"
              value="openingBalanceVeiw"
              v-model="body.roles"
            />
            <label for="openingBalanceVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="openingBalanceAdd"
              name="roles"
              value="openingBalanceAdd"
              v-model="body.roles"
            />
            <label for="openingBalanceAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="openingBalanceEdit"
              name="roles"
              value="openingBalanceEdit"
              v-model="body.roles"
            />
            <label for="openingBalanceEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="openingBalanceDelete"
              name="roles"
              value="openingBalanceDelete"
              v-model="body.roles"
            />
            <label for="openingBalanceDelete">حذف</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="openingBalanceDate"
              name="roles"
              value="openingBalanceDate"
              v-model="body.roles"
            />
            <label for="openingBalanceDate">
              ادخل المعاملات بتاريخ قديم
            </label>
          </div>
        </div>

        <div class="mb-3">
          <h6>الاستلام المخزني</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="receivingInventoryVeiw"
              name="roles"
              value="receivingInventoryVeiw"
              v-model="body.roles"
            />
            <label for="receivingInventoryVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="receivingInventoryAdd"
              name="roles"
              value="receivingInventoryAdd"
              v-model="body.roles"
            />
            <label for="receivingInventoryAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="receivingInventoryEdit"
              name="roles"
              value="receivingInventoryEdit"
              v-model="body.roles"
            />
            <label for="receivingInventoryEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="receivingInventoryDelete"
              name="roles"
              value="receivingInventoryDelete"
              v-model="body.roles"
            />
            <label for="receivingInventoryDelete">حذف</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="receivingInventoryDate"
              name="roles"
              value="receivingInventoryDate"
              v-model="body.roles"
            />
            <label for="receivingInventoryDate">
              ادخل المعاملات بتاريخ قديم
            </label>
          </div>
        </div>

        <div class="mb-3">
          <h6>طلب صرف المخزني</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryStoreCountingReqVeiw"
              name="roles"
              value="inventoryStoreCountingReqVeiw"
              v-model="body.roles"
            />
            <label for="inventoryStoreCountingReqVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryStoreCountingReqAdd"
              name="roles"
              value="inventoryStoreCountingReqAdd"
              v-model="body.roles"
            />
            <label for="inventoryStoreCountingReqAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryStoreCountingReqEdit"
              name="roles"
              value="inventoryStoreCountingReqEdit"
              v-model="body.roles"
            />
            <label for="inventoryStoreCountingReqEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryStoreCountingReqDelete"
              name="roles"
              value="inventoryStoreCountingReqDelete"
              v-model="body.roles"
            />
            <label for="inventoryStoreCountingReqDelete">حذف</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryStoreCountingDX"
              name="roles"
              value="inventoryStoreCountingDX"
              v-model="body.roles"
            />
            <label for="inventoryStoreCountingDX">
              الاعتماد
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryStoreCountingDXS"
              name="roles"
              value="inventoryStoreCountingDXS"
              v-model="body.roles"
            />
            <label for="inventoryStoreCountingDXS">
              الصرف
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryStoreCountingAll"
              name="roles"
              value="inventoryStoreCountingAll"
              v-model="body.roles"
            />
            <label for="inventoryStoreCountingAll">
              مشاهده الكل
            </label>
          </div>
        </div>

        <div class="mb-3">
          <h6>الصرف المخزني</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryStoreCountingVeiw"
              name="roles"
              value="inventoryStoreCountingVeiw"
              v-model="body.roles"
            />
            <label for="inventoryStoreCountingVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryStoreCountingAdd"
              name="roles"
              value="inventoryStoreCountingAdd"
              v-model="body.roles"
            />
            <label for="inventoryStoreCountingAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryStoreCountingEdit"
              name="roles"
              value="inventoryStoreCountingEdit"
              v-model="body.roles"
            />
            <label for="inventoryStoreCountingEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryStoreCountingDelete"
              name="roles"
              value="inventoryStoreCountingDelete"
              v-model="body.roles"
            />
            <label for="inventoryStoreCountingDelete">حذف</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryStoreCountingDate"
              name="roles"
              value="inventoryStoreCountingDate"
              v-model="body.roles"
            />
            <label for="inventoryStoreCountingDate">
              ادخل المعاملات بتاريخ قديم
            </label>
          </div>
          <!-- <div class="p-field-checkbox">
            <Checkbox
              id="inventoryStoreCountingDX"
              name="roles"
              value="inventoryStoreCountingDX"
              v-model="body.roles"
            />
            <label for="inventoryStoreCountingDX">
              اعتماد مباشر
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryStoreCountingDXP"
              name="roles"
              value="inventoryStoreCountingDXP"
              v-model="body.roles"
            />
            <label for="inventoryStoreCountingDXP">
              صرف بدون اعتماد
            </label>
          </div> -->
        </div>

        <div class="mb-3">
          <h6>الجرد المخزني</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryAdjustmentVeiw"
              name="roles"
              value="inventoryAdjustmentVeiw"
              v-model="body.roles"
            />
            <label for="inventoryAdjustmentVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryAdjustmentAdd"
              name="roles"
              value="inventoryAdjustmentAdd"
              v-model="body.roles"
            />
            <label for="inventoryAdjustmentAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryAdjustmentEdit"
              name="roles"
              value="inventoryAdjustmentEdit"
              v-model="body.roles"
            />
            <label for="inventoryAdjustmentEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryAdjustmentDelete"
              name="roles"
              value="inventoryAdjustmentDelete"
              v-model="body.roles"
            />
            <label for="inventoryAdjustmentDelete">حذف</label>
          </div>


            <div class="p-field-checkbox">
            <Checkbox
              id="inventoryAdjustmentDate"
              name="roles"
              value="inventoryAdjustmentDate"
              v-model="body.roles"
            />
            <label for="inventoryAdjustmentDate">
              ادخل المعاملات بتاريخ قديم
            </label>
          </div>

        </div>

        <div class="mb-3">
          <h6>الاتلاف المخزني</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryDamageStoreItemsVeiw"
              name="roles"
              value="inventoryDamageStoreItemsVeiw"
              v-model="body.roles"
            />
            <label for="inventoryDamageStoreItemsVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryDamageStoreItemsAdd"
              name="roles"
              value="inventoryDamageStoreItemsAdd"
              v-model="body.roles"
            />
            <label for="inventoryDamageStoreItemsAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryDamageStoreItemsEdit"
              name="roles"
              value="inventoryDamageStoreItemsEdit"
              v-model="body.roles"
            />
            <label for="inventoryDamageStoreItemsEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryDamageStoreItemsDelete"
              name="roles"
              value="inventoryDamageStoreItemsDelete"
              v-model="body.roles"
            />
            <label for="inventoryDamageStoreItemsDelete">حذف</label>
          </div>


            <div class="p-field-checkbox">
            <Checkbox
              id="inventoryDamageStoreItemsDate"
              name="roles"
              value="inventoryDamageStoreItemsDate"
              v-model="body.roles"
            />
            <label for="inventoryDamageStoreItemsDate">
              ادخل المعاملات بتاريخ قديم
            </label>
          </div>

        </div>

        <div class="mb-3">
          <h6>التحويل المخزني</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryDirectTransferVeiw"
              name="roles"
              value="inventoryDirectTransferVeiw"
              v-model="body.roles"
            />
            <label for="inventoryDirectTransferVeiw">عرض</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryDirectTransferAdd"
              name="roles"
              value="inventoryDirectTransferAdd"
              v-model="body.roles"
            />
            <label for="inventoryDirectTransferAdd">اضافه</label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryDirectTransferEdit"
              name="roles"
              value="inventoryDirectTransferEdit"
              v-model="body.roles"
            />
            <label for="inventoryDirectTransferEdit">تعديل</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="inventoryDirectTransferDelete"
              name="roles"
              value="inventoryDirectTransferDelete"
              v-model="body.roles"
            />
            <label for="inventoryDirectTransferDelete">حذف</label>
          </div>

             <div class="p-field-checkbox">
            <Checkbox
              id="inventoryDirectTransferDate"
              name="roles"
              value="inventoryDirectTransferDate"
              v-model="body.roles"
            />
            <label for="inventoryDirectTransferDate">
              ادخل المعاملات بتاريخ قديم
            </label>
          </div>

        </div>

        <div class="mb-3">
          <h6>التقرير</h6>
          <div class="p-field-checkbox">
            <Checkbox
              id="reportsTotalSalesVeiw"
              name="roles"
              value="reportsTotalSalesVeiw"
              v-model="body.roles"
            />
            <label for="reportsTotalSalesVeiw">اجمالى المبيعات</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSalesClientsVeiw"
              name="roles"
              value="reportsSalesClientsVeiw"
              v-model="body.roles"
            />
            <label for="reportsSalesClientsVeiw">تقرير العملاء</label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSalesContractsVeiw"
              name="roles"
              value="reportsSalesContractsVeiw"
              v-model="body.roles"
            />
            <label for="reportsSalesContractsVeiw">
              تقرير العقود
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSalesContractsDeliveryDateVeiw"
              name="roles"
              value="reportsSalesContractsDeliveryDateVeiw"
              v-model="body.roles"
            />
            <label for="reportsSalesContractsDeliveryDateVeiw">
              تقرير انحراف تنفيذ العقود
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSalesContractsBayVeiw"
              name="roles"
              value="reportsSalesContractsBayVeiw"
              v-model="body.roles"
            />
            <label for="reportsSalesContractsBayVeiw">
              تقرير سداد العقود
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSalesMaterialsVeiw"
              name="roles"
              value="reportsSalesMaterialsVeiw"
              v-model="body.roles"
            />
            <label for="reportsSalesMaterialsVeiw">
              تقرير المخازن
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSalesCollectionVeiw"
              name="roles"
              value="reportsSalesCollectionVeiw"
              v-model="body.roles"
            />
            <label for="reportsSalesCollectionVeiw">
              تقرير التحصيل
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSalesInstallationsVeiw"
              name="roles"
              value="reportsSalesInstallationsVeiw"
              v-model="body.roles"
            />
            <label for="reportsSalesInstallationsVeiw">
              تقرير التركيبات
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSalesTasksVeiw"
              name="roles"
              value="reportsSalesTasksVeiw"
              v-model="body.roles"
            />
            <label for="reportsSalesTasksVeiw">
              تقرير متابعة العملاء المستهدفين
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSalesTasksOrderDoneVeiw"
              name="roles"
              value="reportsSalesTasksOrderDoneVeiw"
              v-model="body.roles"
            />
            <label for="reportsSalesTasksOrderDoneVeiw">
              تقرير مهام العمل
            </label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSalesDistributionsVeiw"
              name="roles"
              value="reportsSalesDistributionsVeiw"
              v-model="body.roles"
            />
            <label for="reportsSalesDistributionsVeiw">
              اوامر التركيب تحت التوزيع
            </label>
          </div>
          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSalesOperationsVeiw"
              name="roles"
              value="reportsSalesOperationsVeiw"
              v-model="body.roles"
            />
            <label for="reportsSalesOperationsVeiw">
              تقرير اوامر التشغيل
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSalesRevisionsVeiw"
              name="roles"
              value="reportsSalesRevisionsVeiw"
              v-model="body.roles"
            />
            <label for="reportsSalesRevisionsVeiw">
              اوامر تشغيل تحت المراجعة
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSalesDeliveryVeiw"
              name="roles"
              value="reportsSalesDeliveryVeiw"
              v-model="body.roles"
            />
            <label for="reportsSalesDeliveryVeiw">
              اوامر التركيب للاستلام النهائي
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSalesDeliveryNotesVeiw"
              name="roles"
              value="reportsSalesDeliveryNotesVeiw"
              v-model="body.roles"
            />
            <label for="reportsSalesDeliveryNotesVeiw">
              تقرير ملاحظات استلام العملاء
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSalesNotificationsVeiw"
              name="roles"
              value="reportsSalesNotificationsVeiw"
              v-model="body.roles"
            />
            <label for="reportsSalesNotificationsVeiw">
              تقرير الاشعارات
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSurveysCircularsVeiw"
              name="roles"
              value="reportsSurveysCircularsVeiw"
              v-model="body.roles"
            />
            <label for="reportsSurveysCircularsVeiw">
              تقرير التعميم
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSalesWelcomeVeiw"
              name="roles"
              value="reportsSalesWelcomeVeiw"
              v-model="body.roles"
            />
            <label for="reportsSalesWelcomeVeiw">
              اغلاق العقود
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsSurveysVeiw"
              name="roles"
              value="reportsSurveysVeiw"
              v-model="body.roles"
            />
            <label for="reportsSurveysVeiw">
              تقرير تقيم العملاء
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsInventoryStoreItemsVeiw"
              name="roles"
              value="reportsInventoryStoreItemsVeiw"
              v-model="body.roles"
            />
            <label for="reportsInventoryStoreItemsVeiw">
              كارت الصنف اجمالي
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsinventoryStoreItemsInfoWVeiw"
              name="roles"
              value="reportsinventoryStoreItemsInfoWVeiw"
              v-model="body.roles"
            />
            <label for="reportsinventoryStoreItemsInfoWVeiw">
              اجمالى كارت الصنف بالمستودع
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsInventoryStoreItemsInfo"
              name="roles"
              value="reportsInventoryStoreItemsInfo"
              v-model="body.roles"
            />
            <label for="reportsInventoryStoreItemsInfo">
              كارت الصنف تفصيلى
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsInventoryStoreItemsInfoTwo"
              name="roles"
              value="reportsInventoryStoreItemsInfoTwo"
              v-model="body.roles"
            />
            <label for="reportsInventoryStoreItemsInfoTwo">
              كارت الصنف مفصل
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsinventoryBalanceVeiw"
              name="roles"
              value="reportsinventoryBalanceVeiw"
              v-model="body.roles"
            />
            <label for="reportsinventoryBalanceVeiw">
              المعاملات المخزنيه
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsopeningBalanceVeiw"
              name="roles"
              value="reportsopeningBalanceVeiw"
              v-model="body.roles"
            />
            <label for="reportsopeningBalanceVeiw">
              الأرصده الافتتاحيه للمخازن
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsinventoryDamageStoreItemsVeiw"
              name="roles"
              value="reportsinventoryDamageStoreItemsVeiw"
              v-model="body.roles"
            />
            <label for="reportsinventoryDamageStoreItemsVeiw">
              الأتلاف المخزني - تفصيلي
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsreceivingInventoryVeiw"
              name="roles"
              value="reportsreceivingInventoryVeiw"
              v-model="body.roles"
            />
            <label for="reportsreceivingInventoryVeiw">
              الاستلام المخزني - تفصيلي
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsinventory_store_counting_reqVeiw"
              name="roles"
              value="reportsinventory_store_counting_reqVeiw"
              v-model="body.roles"
            />
            <label for="reportsinventory_store_counting_reqVeiw">
              طلب الصرف المخزني - تفصيلي
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsinventoryStoreCountingVeiw"
              name="roles"
              value="reportsinventoryStoreCountingVeiw"
              v-model="body.roles"
            />
            <label for="reportsinventoryStoreCountingVeiw">
              الصرف المخزني - تفصيلي
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsinventoryStoreCountingTwoVeiw"
              name="roles"
              value="reportsinventoryStoreCountingTwoVeiw"
              v-model="body.roles"
            />
            <label for="reportsinventoryStoreCountingTwoVeiw">
              الصرف المخزني - مفصل
            </label>
          </div>

          <div class="p-field-checkbox">
            <Checkbox
              id="reportsinventoryAdjustmentVeiw"
              name="roles"
              value="reportsinventoryAdjustmentVeiw"
              v-model="body.roles"
            />
            <label for="reportsinventoryAdjustmentVeiw">
              الجرد المخزني - تفصيلي
            </label>
          </div>
        </div>
      </div>

      <div class="mb-3 text-center">
        <Button
          label="تعديل"
          icon="pi pi-pencil"
          v-tooltip="'تعديل'"
          class="p-ml-2 p-button-info"
          @click="update()"
          v-if="id"
        />
        <Button
          v-else
          label="اضافة جديد"
          icon="pi pi-plus"
          v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          @click="save()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        name: null,
        roles: [],
      },
      id: null,
      allRoles: false,

      isInvalid: false,
      disabledAdd: false,
    };
  },
  methods: {
    onToggle() {},
    save() {
      if (this.body.name) {
        this.disabledAdd = true;
        this.$http.post(`roles`, this.body).then(
          () => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافة بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/users/roles');
          },
          (err) => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
    update() {
      if (this.body.name) {
        this.disabledAdd = true;
        this.$http.put(`roles/${this.id}`, this.body).then(
          () => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/users/roles');
          },
          (err) => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
  },
  created() {
    if (!this.$checkRoles('rolesAdd')) {
      this.$router.push('/admin/');
    }
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`roles/${this.id}`).then(
        (res) => {
          this.body = res.data;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
  watch: {
    allRoles(val) {
      if (val) {
        this.body.roles = [
          'systemMenuVeiw',
          'usersMenuVeiw',
          'salesMenuVeiw',
          'technicalSupportMenuVeiw',
          'secretaryMenuVeiw',
          'systemVeiw',
          'usersVeiw',
          'salesVeiw',
          'technicalSupportVeiw',
          'secretaryVeiw',
          'companyVeiw',
          'notificationsVeiw',
          'branchesVeiw',
          'branchesAdd',
          'branchesEdit',
          'branchesDelete',
          'countriesVeiw',
          'countriesAdd',
          'countriesEdit',
          'countriesDelete',
          'citiesVeiw',
          'citiesAdd',
          'citiesEdit',
          'citiesDelete',
          'currenciesVeiw',
          'currenciesAdd',
          'currenciesEdit',
          'currenciesDelete',
          'productsVeiw',
          'productsAdd',
          'productsEdit',
          'productsDelete',
'inventoryAdjustmentDate',
          'inventoryStoreCountingDate',
          'inventoryDirectTransferDate',
          'itemsEdit',
          'itemsDelete',
          'surveysVeiw',
          'surveysAdd',
          'surveysEdit',
          'surveysDelete',
          'administrationVeiw',
          'administrationAdd',
          'administrationEdit',
          'administrationDelete',
          'sectionsVeiw',
          'sectionsAdd',
          'employeesVeiw',
          'employeesAdd',
          'receivingInventoryDate',
          'sectionsDelete',
          'sectionsEdit',
          'employeesEdit',
          'employeesDelete',
          'clientGroupsVeiw',
          'clientGroupsAdd',
          'clientGroupsEdit',
          'clientGroupsDelete',
          'installationsStepsVeiw',
          'installationsStepsAdd',
          'installationsStepsEdit',
          'installationsStepsDelete',
          'rolesVeiw',
          'rolesAdd',
          'rolesEdit',
          'rolesDelete',
          'adminsVeiw',
          'adminsAdd',
          'adminsEdit',
          'adminsDelete',
          'clientsVeiw',
          'clientsAdd',
          'clientsEdit',
          'clientsDelete',
          'contractsVeiw',
          'contractsAdd',
          'contractsEdit',
          'contractsDelete',
          'contractsToInstalltions',
          'contractsWithoutPayment',
          'paymentTypeAdd',
          'materialsVeiw',
          'materialsAdd',
          'materialsDelete',
          'orderDone',
          'editDone',
          'chargedDone',
          'installationsVeiw',
          'installationsEdit',
          'inventoryDamageStoreItemsDate',
          'deliveryVeiw',
          'installtionsToDelivery',
          'deliveryToSecretary',
          'welcomeEdit',
          'welcomeVeiw',
          'administrationsEdit',
          'administrationsDelete',
          'itemsAdd',
          'collectionVeiw',
          'taxesVeiw',
          'logsVeiw',
          'taxesEdit',
          'taxesAdd',
          'taxesDelete',
          'tasksVeiw',
          'tasksAdd',
          'tasksEdit',
          'tasksDelete',
          'tasksAll',
          'distributionsVeiw',
          'operationsVeiw',
          'revisionsVeiw',
          'deliveryNotesVeiw',
          'operationsAll',
          'reportsMenuVeiw',
          'reportsTotalSalesVeiw',
          'reportsSalesClientsVeiw',
          'reportsSalesContractsVeiw',
          'reportsSalesCollectionVeiw',
          'reportsSalesInstallationsVeiw',
          'reportsSurveysVeiw',
          'materialsEdit',
          'deliveryToInstalltion',
          'deliveryToDelivery',
          'purchasesVeiw',
          'purchasesAdd',
          'purchasesEdit',
          'purchasesDelete',
          'warehouseVeiw',
          'warehouseAdd',
          'warehouseEdit',
          'warehouseDelete',
          'reportsSalesTasksVeiw',
          'reportsSalesOperationsVeiw',
          'paymentTypeEdit',
          'orderDoneEdit',
          'editDoneEdit',
          'chargedDoneEdit',
          'tasksOrderVeiw',
          'tasksOrderAdd',
          'tasksOrderEdit',
          'tasksOrderDelete',
          'tasksOrderAll',
          'tasksOrderDoneVeiw',
          'tasksOrderDoneEdit',
          'tasksOrderDoneAll',
          'installationsVeiwAll',
          'distributionsVeiwAll',
          'revisionsVeiwAll',
          // 'secretarySurveysVeiw',
          'circularsVeiw',
          'circularsAdd',
          'circularsDelete',
          'contractsShowAllData',
          'purchasesOrderDone',
          'purchasesOrderDelivery',
          'reportsSalesContractsDeliveryDateVeiw',
          'reportsSalesContractsBayVeiw',
          'reportsSalesMaterialsVeiw',
          'reportsSalesTasksOrderDoneVeiw',
          'reportsSalesDistributionsVeiw',
          'reportsSalesRevisionsVeiw',
          'reportsSalesDeliveryVeiw',
          'reportsSalesNotificationsVeiw',
          'reportsSurveysCircularsVeiw',
          'reportsSalesWelcomeVeiw',
          'reportsSalesDeliveryNotesVeiw',
          'homeNumberingVeiw',
          'homedoutVeiw',
          'homeLineVeiw',
          'homePriceVeiw',
          'inventoryMenuVeiw',
          'inventoryStoreCountingDX',
          'inventoryStoreCountingAll',
          'openingBalanceDate',

          'reportsInventoryStoreItemsVeiw',
          'reportsInventoryStoreItemsInfo',
          'reportsinventoryBalanceVeiw',
          'reportsopeningBalanceVeiw',
          'reportsinventoryDamageStoreItemsVeiw',
          'reportsreceivingInventoryVeiw',
          'reportsinventoryStoreCountingVeiw',
          'reportsinventoryStoreCountingTwoVeiw',
          'reportsinventoryAdjustmentVeiw',
          'reportsinventory_store_counting_reqVeiw',
          'itemsGroupsVeiw',
          'itemsGroupsAdd',
          'itemsGroupsEdit',
          'itemsGroupsDelete',
          'itemsTypesVeiw',
          'itemsTypesAdd',
          'itemsTypesEdit',
          'itemsTypesDelete',
          'itemsUnitsVeiw',
          'itemsUnitsAdd',
          'itemsUnitsEdit',
          'itemsUnitsDelete',
          'storeItemsVeiw',
          'storeItemsAdd',
          'storeItemsEdit',
          'storeItemsDelete',
          'openingBalanceVeiw',
          'openingBalanceAdd',
          'openingBalanceEdit',

          'openingBalanceDelete',
          'receivingInventoryVeiw',
          'receivingInventoryAdd',
          'receivingInventoryEdit',
          'receivingInventoryDelete',
          'inventoryStoreCountingVeiw',
          'inventoryStoreCountingAdd',
          'inventoryStoreCountingEdit',
          'inventoryStoreCountingDelete',
          'inventoryAdjustmentVeiw',
          'inventoryAdjustmentAdd',
          'inventoryAdjustmentEdit',
          'inventoryAdjustmentDelete',
          'inventoryDamageStoreItemsVeiw',
          'inventoryDamageStoreItemsAdd',
          'inventoryDamageStoreItemsEdit',
          'inventoryDamageStoreItemsDelete',
          'inventoryDirectTransferVeiw',
          'inventoryDirectTransferAdd',
          'inventoryDirectTransferEdit',
          'inventoryDirectTransferDelete',
          'reportsInventoryStoreItemsInfoTwo',
          'reportsinventoryStoreItemsInfoWVeiw',
          'contractsDate',
          'inventoryStoreCountingReqVeiw',
          'inventoryStoreCountingDXS',
          'inventoryStoreCountingReqAdd',
          'inventoryStoreCountingReqEdit',
          'inventoryStoreCountingReqDelete',
        ];
      }

      if (val == false) {
        this.body.roles = [];
      }
    },
  },
};
</script>
<style>
.roles label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
